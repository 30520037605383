<template>
  <div>
    <Edit
      :item="currentItem"
      :access="currentAccess"
      :update="updateItem"
      :close="closeEdit"
      :descSingular="descSingular"
    ></Edit>
    <div>
      <div class="content">
        <div class="content-wrapper">
          <Loading :isActive="loading"></Loading>
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="descPlural">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Imagem 100x100</th>
                      <th>Título</th>
                      <th>Visível</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items">
                      <td>
                        <img class="img-thumbnail" :src="item.imagem" />
                      </td>
                      <td>
                        <strong>{{ item.descricao }}</strong>
                      </td>
                      <td>
                        <span class="label success" v-if="item.visivel_menu"
                          >Ativo</span
                        >
                        <span class="label error" v-else>Inativo</span>
                      </td>
                      <td class="actions">
                        <button
                          type="button"
                          class="button button-primary"
                          v-on:click="openEdit(item)"
                        >
                          <fa-icon icon="pencil-alt" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CTab>
          </CTabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import clone from "just-clone";
import { get, post, put, del } from "@/helpers/apiRequest";
import Edit from "./Edit";

export default {
  name: "departamentos",
  components: {
    Edit,
  },
  data() {
    return {
      descPlural: "",
      descSingular: "",
      loading: false,
      items: [],
      modulos: [],
      currentItem: {},
      currentAccess: false,
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem("app"));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === "ADMIN_DEPARTAMENTO"
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadDepartamentos() {
      this.loading = true;
      get("/admin/site-departamentos")
        .then((json) => {
          this.items = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    openEdit(item, showLoading = true) {
      this.currentItem = clone(item);
      if (showLoading) {
        this.loading = true;
      }
      get(`/admin/site-departamentos/${this.currentItem.id}`).then((json) => {
        this.currentAccess = json;
        if (showLoading) {
          this.loading = false;
          this.$modal.show("departamentosEdit");
        }
      });
    },
    closeEdit() {
      this.$modal.hide("departamentosEdit");
    },
    updateItem() {
      const { id } = this.currentItem;
      put(`/admin/site-departamentos/${id}`, this.currentItem)
        .then((json) => {
          this.closeEdit();
          this.loadDepartamentos();
          this.$swal({
            title: "Salvo com sucesso",
            text: json.message,
            showCancelButton: false,
            cancelButtonText: "Fechar",
            showConfirmButton: true,
            icon: "success",
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Falha ao Salvar",
            text: error.message,
            showCancelButton: true,
            cancelButtonText: "Fechar",
            showConfirmButton: false,
            icon: "error",
          });
        });
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadDepartamentos();
    this.loadDescricaoModulo();
  },
};
</script>
