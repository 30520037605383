<style scoped>
img.spinner {
  width: 20px;
  margin-right: 10px;
}
</style>
<template>
  <modal
    name="departamentosEdit"
    height="auto"
    width="900"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Editando ${descSingular}`">
            <div class="d-flex flex-wrap">
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">ID</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="item.id"
                    disabled
                  />
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Título</label>
                  <input
                    class="form-control"
                    v-model="item.descricao"
                    disabled
                  />
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Ativo ERP</label>
                  <select class="form-control" v-model="item.ativo" disabled>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Vísivel Menu</label>
                  <select class="form-control" v-model="item.visivel_menu">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.visivel_menu") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Ordem Exibição</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="item.ordem"
                  />
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Meta Title</label>
                  <input class="form-control" v-model="item.meta_title" />
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Meta Description</label>
                  <input class="form-control" v-model="item.meta_description" />
                </div>
              </div>
              <div class="col-1-3 add-image">
                <image-picker
                  label="Imagem 100x100"
                  placeholder="Colar URL ou clique ao lado para upload"
                  v-model="item.imagem"
                />
              </div>
              <div class="col-12">
                <image-picker
                  label="Imagem Mobile"
                  placeholder="Colar URL ou clique ao lado para upload"
                  v-model="item.imagem_mobile"
                />
              </div>
              <div class="col-12">
                <image-picker
                  label="Imagem Desktop"
                  placeholder="Colar URL ou clique ao lado para upload"
                  v-model="item.imagem_desktop"
                />
              </div>
            </div>
            <div class="xrow edit-buttons">
              <button
                :class="{
                  'button button-success': true,
                  'button-disabled': validation.hasError(),
                }"
                v-on:click="handleSubmit"
              >
                Alterar
              </button>
              &nbsp;
              <button class="button" v-on:click="close">Fechar</button>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import spinner from "../../assets/images/spinner.svg";
import CloseModal from "../../components/CloseModal";
import ImagePicker from "../../components/ImagePicker";

export default {
  name: "departamentosEdit",
  props: ["item", "access", "update", "close", "modulos", "descSingular"],
  components: { CloseModal, ImagePicker },
  data() {
    return {
      loadingModulos: [],
      spinner,
    };
  },
  computed: {
    pivots() {
      if (this.access && this.access.perfisModulos) {
        return this.access.perfisModulos.reduce((acc, pivot) => {
          acc.push(pivot.modulo_id);
          return acc;
        }, []);
      }
      return [];
    },
  },
  validators: {
    "item.visivel_menu": (value) => Validator.value(value).required(),
  },
  methods: {
    isModuloIncluso(modulo) {
      return this.pivots.indexOf(+modulo.id) >= 0;
    },
    isLoadingModulo(modulo) {
      return this.loadingModulos.indexOf(modulo.id) >= 0;
    },
    removeLoadingModulo(modulo) {
      let idx = this.loadingModulos.indexOf(modulo.id);
      if (idx >= 0) {
        this.loadingModulos.splice(idx, 1);
      }
    },
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
  },
};
</script>
